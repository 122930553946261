/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IPendingDepositsStore,
	IGetPendingDepositsRequest,
	IPendingDepositsItem,
	IFilterParams,
	IApproveRequestPayload,
	IApproveAllRequestPayload,
	IRejectRequestPayload,
	IRejectAllRequestPayload,
} from './types';
// ==========================================:

export const initialState: IPendingDepositsStore = {
	pendingDepositsLoading: false,
	depositsExportLoading: false,
	pendingDeposits: null,
	filterParams: null,
	approveLoader: false,
	rejectLoader: false,
	approveAllLoader: false,
	rejectAllLoader: false,
};

// ==========================================:

const pendingDeposits = createSlice({
	name: '@@pendingDeposits',
	initialState,
	reducers: {
		getPendingDepositsRequest: (state, { payload }: PayloadAction<IGetPendingDepositsRequest>) => {
			const pendingDepositsState = state;
			if (payload.export !== 1) {
				pendingDepositsState.pendingDepositsLoading = true;
			} else {
				pendingDepositsState.depositsExportLoading = true;
			}
		},
		getPendingDepositsSuccess: (state, { payload }: PayloadAction<IPendingDepositsItem[]>) => {
			const pendingDepositsState = state;
			pendingDepositsState.pendingDepositsLoading = false;
			pendingDepositsState.pendingDeposits = payload;
		},
		getPendingDepositsExportSuccess: (state) => {
			const pendingDepositsState = state;
			pendingDepositsState.depositsExportLoading = false;
		},
		getPendingDepositsError: (state) => {
			const pendingDepositsState = state;
			pendingDepositsState.pendingDepositsLoading = false;
			pendingDepositsState.depositsExportLoading = false;
		},
		setFilterParams: (state, action: PayloadAction<IFilterParams>) => {
			const { payload } = action;
			const pendingDepositsState = state;
			pendingDepositsState.filterParams = payload;
		},
		approveDepositRequest: (state, action: PayloadAction<IApproveRequestPayload>) => {
			const pendingDepositsState = state;
			pendingDepositsState.approveLoader = true;
		},
		approveDepositSuccess: (state, { payload }: PayloadAction<{ id: number }>) => {
			const pendingDepositsState = state;
			pendingDepositsState.approveLoader = false;
			if (!pendingDepositsState.pendingDeposits) {
				return;
			}
			pendingDepositsState.pendingDeposits = pendingDepositsState.pendingDeposits.filter((item) => {
				return item.id !== payload.id;
			});
		},
		approveAllDepositsRequest: (state, action: PayloadAction<IApproveAllRequestPayload>) => {
			const pendingDepositsState = state;
			pendingDepositsState.approveAllLoader = true;
		},
		approveAllDepositsSuccess: (state, { payload }: PayloadAction<{ ids: number[] }>) => {
			const pendingDepositsState = state;
			pendingDepositsState.approveAllLoader = false;
			if (!pendingDepositsState.pendingDeposits) {
				return;
			}
			pendingDepositsState.pendingDeposits = pendingDepositsState.pendingDeposits.filter((item) => {
				return !payload.ids.includes(item.id);
			});
		},
		rejectDepositRequest: (state, action: PayloadAction<IRejectRequestPayload>) => {
			const pendingDepositsState = state;
			pendingDepositsState.rejectLoader = true;
		},
		rejectDepositSuccess: (state, { payload }: PayloadAction<{ id: number }>) => {
			const pendingDepositsState = state;
			pendingDepositsState.rejectLoader = false;
			if (!pendingDepositsState.pendingDeposits) {
				return;
			}
			pendingDepositsState.pendingDeposits = pendingDepositsState.pendingDeposits.filter((item) => {
				return item.id !== payload.id;
			});
		},
		rejectAllDepositsRequest: (state, action: PayloadAction<IRejectAllRequestPayload>) => {
			const pendingDepositsState = state;
			pendingDepositsState.rejectAllLoader = true;
		},
		rejectAllDepositsSuccess: (state, { payload }: PayloadAction<{ ids: number[] }>) => {
			const pendingDepositsState = state;
			pendingDepositsState.rejectAllLoader = false;
			if (!pendingDepositsState.pendingDeposits) {
				return;
			}
			pendingDepositsState.pendingDeposits = pendingDepositsState.pendingDeposits.filter((item) => {
				return !payload.ids.includes(item.id);
			});
		},
		initState: () => initialState,
	},
});

export default pendingDeposits.reducer;
export const {
	getPendingDepositsRequest,
	getPendingDepositsSuccess,
	getPendingDepositsExportSuccess,
	getPendingDepositsError,
	setFilterParams,
	approveDepositRequest,
	approveDepositSuccess,
	approveAllDepositsRequest,
	approveAllDepositsSuccess,
	rejectDepositRequest,
	rejectDepositSuccess,
	rejectAllDepositsRequest,
	rejectAllDepositsSuccess,
} = pendingDeposits.actions;
